import {User} from '@hconnect/apiclient/src/response/User'
import {DecodedToken} from '@hconnect/apiclient/src/types'
import {LegalPage} from '@hconnect/common/components/legal/LegalPage'
import {TermsAndConditions} from '@hconnect/common/components/legal/TermsAndConditions'
import {ApiProvider} from '@hconnect/common/hooks/useApi'
import {Env, Permission, WhiteList} from '@hconnect/common/hproduce'
import {NotificationProvider} from '@hconnect/uikit'
import {Shell} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Link as RouterLink,
  generatePath,
  Navigate
} from 'react-router-dom'

import {HLog} from './common/utils/logging'
import {trackAnalyticsEvent} from './common/utils/trackAnalyticsEvents'
import {GlobalErrorBoundary} from './containers/common/GlobalErrorBoundary'
import {FooterContent} from './containers/footer/FooterContent'
import {HeaderContent} from './containers/header/HeaderContent'
import {Api} from './hooks/api/types'
import {CockpitToursProvider} from './hooks/useCockpitTours'
import {ConfigProvider, useConfigService} from './hooks/useConfig'
import {useFeatureFlag} from './hooks/useFeatureFlag'
import {GlobalContextProvider} from './hooks/useGlobalContext'
import {InstructionsPageLastVisitedProvider} from './hooks/useInstructionsPageLastVisited'
import {DocumentsPage} from './pages/DocumentsPage'
import {EventsPage} from './pages/EventsPage'
import {ManageShiftPage} from './pages/ManageShiftsPage'
import {PerformancePage} from './pages/PerformancePage'
import {RecurringTasksPage} from './pages/RecurringTasksPage'
import {ShiftSummaryPage} from './pages/ShiftSummaryPage'
import {SimpleErrorPage} from './pages/SimpleErrorPage'
import {TasksPage} from './pages/TasksPage'
import {
  routeToManageShifts,
  routeToTasks,
  routeToEvents,
  routeToSummary,
  routeToRecurringTasks,
  routeToDocuments,
  routeToTermsConditions,
  routeToPrivacyPolicy,
  routeToPerformance
} from './routes'

type InitParams = {
  loginResponse: {loggedIn: true; decodedToken: DecodedToken}
  api: Api
  user: User
  permissions: Permission[]
  whiteList: WhiteList
  plantId?: string
}

export const App = ({api, user, loginResponse, permissions, whiteList, plantId}: InitParams) => {
  const {loginFlow, axiosInstance} = api
  const {user_id: userId, email} = loginResponse.decodedToken
  const configService = useConfigService(axiosInstance, plantId as string)
  const {t} = useTranslation()

  const config = configService.data

  if (configService.isLoading) return null

  if (configService.error || !config) {
    // TODO: remove old app name events with HCP-33491
    HLog('cockpit')
      .tags({
        env: process.env.REACT_APP_STAGE ?? 'unknown',
        user: userId,
        roll: user.hasRoles.join()
      })
      .warning('fail to load the app config')

    trackAnalyticsEvent('onErrorPageTracking', {
      plantId: plantId as string,
      userId,
      errorMessage: `We are very sorry, we couldn't start the application with the provided plantId: ${plantId}`
    })
    return (
      <SimpleErrorPage
        message={`${t('error.failToLoadAppConfig')}${plantId}`}
        withRedirectButton={true}
        env={process.env.REACT_APP_STAGE as Env}
      />
    )
  }

  const noShifts = !config.shifts || config.shifts.length === 0

  return (
    <NotificationProvider anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
      <Router>
        <GlobalContextProvider
          initialState={{
            user: {
              userId,
              email,
              name: user.name ?? 'unknown user name',
              hasRoles: user.hasRoles
            },
            whiteList,
            permissions,
            config
          }}
        >
          <ConfigProvider>
            <CockpitToursProvider>
              <InstructionsPageLastVisitedProvider>
                <GlobalErrorBoundary>
                  <ApiProvider secureApi={null} publicApi={axiosInstance}>
                    <Shell
                      isResponsive
                      header={
                        <HeaderContent onLogout={() => void loginFlow.startLogoutProcess()} />
                      }
                      boxed={false}
                      footer={<FooterContent plantId={plantId} />}
                    >
                      <AppShellContent user={user} plantId={plantId} noShifts={noShifts} />
                    </Shell>
                  </ApiProvider>
                </GlobalErrorBoundary>
              </InstructionsPageLastVisitedProvider>
            </CockpitToursProvider>
          </ConfigProvider>
        </GlobalContextProvider>
      </Router>
    </NotificationProvider>
  )
}

const AppShellContent = ({
  user,
  plantId,
  noShifts
}: {
  user: User
  plantId?: string
  noShifts: boolean
}) => {
  const isTermsAndConditionsEnabled = useFeatureFlag('termsAndConditions')

  return (
    <>
      {isTermsAndConditionsEnabled && <TermsAndConditions user={user} />}
      <Routes>
        <Route path={routeToManageShifts} element={<ManageShiftPage />} />
        {plantId && noShifts && (
          <Route
            path="*"
            element={<Navigate to={generatePath(routeToManageShifts, {plantId})} />}
          />
        )}
        <Route path={routeToEvents} element={<EventsPage />} />
        <Route path={routeToTasks} element={<TasksPage />} />
        <Route path={routeToSummary} element={<ShiftSummaryPage />} />
        <Route path={routeToPerformance} element={<PerformancePage />} />
        <Route path={routeToRecurringTasks} element={<RecurringTasksPage />} />
        <Route path={routeToDocuments} element={<DocumentsPage />} />
        <Route path={routeToDocuments} element={<DocumentsPage />} />
        {isTermsAndConditionsEnabled && (
          <Route
            path={routeToTermsConditions}
            element={<LegalPage routerLinkComponent={RouterLink} user={user} type="tc" />}
          />
        )}
        {isTermsAndConditionsEnabled && (
          <Route
            path={routeToPrivacyPolicy}
            element={<LegalPage routerLinkComponent={RouterLink} user={user} type="dpp" />}
          />
        )}
        <Route
          path="*"
          element={<Navigate to={generatePath(routeToEvents, {plantId: plantId as string})} />}
        />
      </Routes>
    </>
  )
}
